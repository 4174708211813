/* eslint-disable react/no-danger */
import React, { FC, useState, useMemo } from 'react';
import { Layout, ArticleContent, Pagination } from 'components';
import { Link } from 'gatsby';
import UserIcon from 'assets/svg/usericon.inline.svg';
import ChatIcon from 'assets/svg/chat.inline.svg';
import RightIcon from 'assets/svg/rightarrow.inline.svg';
import { normalizePath } from 'utils/get-url-path';
import { truncate } from 'utils';
import { AllPostsQuery } from 'queries/allPosts';
import Img from 'gatsby-image';
import moment from 'moment';
import lunr from 'lunr';

const PageSize = 4;

const SearchPage: FC = ({ location }: any) => {
  const data = AllPostsQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const handleonClick = (page: any) => {
    setCurrentPage(page);
  };
  const searchValue = location.search.slice(1);
  const searchPosts = data.Posts.edges.map((item: any) => {
    const tags = item.node.tags.nodes.map(tag => tag.name);
    const categories = item.node.categories.nodes.map(tag => tag.name);
    return {
      id: item.node.id,
      title: item.node.title,
      content: item.node.content,
      tags: tags,
      categories: categories,
    };
  });
  const index = lunr(function () {
    this.ref('id');
    this.field('title');
    this.field('categories');
    this.field('tags');
    this.field('content');
    searchPosts.forEach(function (doc: any) {
      this.add(doc);
    }, this);
  });
  const searchResults = index.search(`${searchValue}*`);
  const searchIds = new Set(searchResults.map(({ ref }) => ref));
  const posts = data.Posts.edges.filter((item: any) =>
    searchIds.has(item.node.id)
  );
  const postsData: any = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return posts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, posts]);
  return (
    <Layout>
      <div className="main-page">
        <div>
          <h1 className="page-title">
            {posts.length ? `Results for '${searchValue}'` : 'no results found'}
          </h1>
        </div>
        <div className="articles-wrapper">
          {postsData.map((post: any) => {
            const { content } = post.node;
            const trimcontent = truncate(content, 250);
            const articletitle = post.node.title;
            const trimtitle = truncate(articletitle, 45);
            return (
              <div className="articles-item" key={post.node.title}>
                <div className="articles-image">
                  {post?.node.featuredImage && (
                    <Img
                      fluid={
                        post.node.featuredImage.node.localFile
                          ?.childrenImageSharp[0].fluid
                      }
                    />
                  )}
                </div>
                <div className="articles-content">
                  <div className="articles-content-item-description">
                    <div className="articles-content-button">
                      <Link to={normalizePath(post.node.uri)}>
                        <h5 className="articles-title">{trimtitle}</h5>
                      </Link>
                      <div className="articles-details">
                        <span>
                          <UserIcon /> {post.node.author.node.name}
                        </span>
                        <span className="articles-list-items">
                          {' '}
                          {moment(post.node.date).format('MMMM D, YYYY')}
                        </span>
                        <span className="articles-list-items">
                          <ChatIcon /> {post.node.commentCount}
                        </span>
                      </div>
                      {!!post.node.content && (
                        <ArticleContent ArticleSummary={trimcontent} />
                      )}
                    </div>
                    <div className="articles-category">
                      <Link to={normalizePath(post.node.uri)}>
                        <span className="articles-link">Read More</span>{' '}
                        <RightIcon />
                      </Link>
                      <div className="articles-category-value">
                        <span>{post.node.categories.nodes[0].name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={posts.length}
          pageSize={PageSize}
          onPageChange={handleonClick}
        />
      </div>
    </Layout>
  );
};

export default SearchPage;
